$wt-resources-prefix: "../../../" !default;
$wt-theme-prefix: "#{$wt-resources-prefix}themes/bootstrap/5/" !default;

$wt-date-edit-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='#6c757d'><path fill-rule='evenodd' d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'/><path d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z'/></svg>") !default;
$wt-date-edit-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='#343a40'><path fill-rule='evenodd' d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'/><path d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z'/></svg>") !default;
$wt-date-edit-bg-position: right .73em center !default;
$wt-date-edit-bg-size: 1.125em 1.125em !default;
$wt-date-edit-feedback-icon-position: $form-select-feedback-icon-position !default;
$wt-date-edit-feedback-icon-size: $form-select-feedback-icon-size !default;
$wt-date-edit-feedback-icon-padding-end: $form-select-feedback-icon-padding-end !default;

$wt-time-edit-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='#6c757d'><path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z'/><path fill-rule='evenodd' d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z'/></svg>") !default;
$wt-time-edit-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='#343a40'><path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z'/><path fill-rule='evenodd' d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z'/></svg>") !default;
$wt-time-edit-bg-position: right .73em center !default;
$wt-time-edit-bg-size: 1.125em 1.125em !default;
$wt-time-edit-feedback-icon-position: $form-select-feedback-icon-position !default;
$wt-time-edit-feedback-icon-size: $form-select-feedback-icon-size !default;
$wt-time-edit-feedback-icon-padding-end: $form-select-feedback-icon-padding-end !default;

$wt-panel-collapse-icon: $accordion-button-icon !default;
$wt-panel-collapse-icon-width: $accordion-icon-width !default;
$wt-panel-collapse-active-icon: $accordion-button-active-icon !default;
$wt-panel-collapse-icon-transform: $accordion-icon-transform !default;
$wt-panel-collapse-icon-transition: $accordion-icon-transition !default;

$wt-spinbox-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1.2em' height='1.2em' viewBox='-5 0 30 16'><path transform='translate(0, -10)' stroke='#6c757d' stroke-width='2' fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/><path transform='translate(0, 10)' fill-rule='evenodd' stroke='#6c757d' stroke-width='2'  d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$wt-spinbox-icon-up: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1.2em' height='1.2em' viewBox='-5 0 30 16'><path transform='translate(0, -10)' stroke='#343a40' stroke-width='2'  fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/><path transform='translate(0, 10)' fill-rule='evenodd' stroke='#6c757d' stroke-width='2'  d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$wt-spinbox-icon-down: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1.2em' height='1.2em' viewBox='-5 0 30 16'><path transform='translate(0, -10)' stroke='#6c757d' stroke-width='2'  fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/><path transform='translate(0, 10)' fill-rule='evenodd' stroke='#343a40' stroke-width='2'  d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$wt-spinbox-bg-position: right .73em center !default;
$wt-spinbox-bg-size: 1.125em 1.125em !default;
$wt-spinbox-feedback-icon-position: $form-select-feedback-icon-position !default;
$wt-spinbox-feedback-icon-size: $form-select-feedback-icon-size !default;
$wt-spinbox-feedback-icon-padding-end: $form-select-feedback-icon-padding-end !default;
