.Wt-filedropzone {
  width: 200px;
  height: 200px;
  background-color: lightgrey;
  border: thick dashed grey;

  &.Wt-dropzone-hover {
    border-color: green;

    * {
      pointer-events: none;
    }
  }

  &.Wt-dropzone-dragstyle {
    position: relative;
    z-index: 20001;
  }

  &.Wt-dropzone-indication {
    border-color: green;
  }
}

.Wt-fileupload-hidden {
  display: none !important;
}

.Wt-dropcover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;

  &.Wt-dropzone-dragstyle {
    z-index: 20000;
  }
}
