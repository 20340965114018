.Wt-dateedit,
.Wt-dateedit.form-control {
  background-image: escape-svg($wt-date-edit-icon);
  background-repeat: no-repeat;
  background-position: $wt-date-edit-bg-position;
  background-size: $wt-date-edit-bg-size;

  &.hover {
    background-image: escape-svg($wt-date-edit-icon-hover);
  }

  @if $enable-validation-icons {
    &.form-control {
      @each $state, $data in $form-validation-states {
        @include form-validation-state-selector($state) {
          padding-right: $wt-date-edit-feedback-icon-padding-end;
          background-image: escape-svg($wt-date-edit-icon), escape-svg(map-get($data, "icon"));
          background-position: $wt-date-edit-bg-position, $wt-date-edit-feedback-icon-position;
          background-size: $wt-date-edit-bg-size, $wt-date-edit-feedback-icon-size;

          &.hover {
            background-image: escape-svg($wt-date-edit-icon-hover), escape-svg(map-get($data, "icon"));
          }
        }
      }
    }
  }

  &.hover,
  &.active,
  &:active {
    cursor: default;
  }
}
