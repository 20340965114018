.Wt-icon {
  display: inline-block;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center left;
  width: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: text-top;
}
