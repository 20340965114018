.navbar {
  .navbar-inner .container {
    margin-right: 0;
    margin-left: 0;
  }

  .nav {
    > li {
      // Add drop down menu support in navbar
      &.submenu > a {
        &:hover,
        &.active {
          color: #555555;
          text-decoration: none;
          background-color: #e7e7e7;
          box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
        }
      }

      // Fix submenus in popup menus in navbar
      > .dropdown-menu.submenu {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    // Fix open drop down menu
    li > .dropdown-toggle.active {
      color: #555555;
      background-color: #e5e5e5;
    }
  }
}
