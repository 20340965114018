.Wt-datepicker {
  input {
    display: inline;
  }
}

.Wt-datepicker-icon {
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
}
